import homeImg from "../images/home.jpg"
import slide1 from "../images/carousel-aboutpage-1.png"
import slide2 from "../images/carousel-aboutpage-2.png"
import slide3 from "../images/carousel-aboutpage-3.jpg"
import slide4 from "../images/carousel-aboutpage-4.jpg"
import slide5 from "../images/carousel-aboutpage-5.png"
import locationIcon from "../images/location.png"

export const home = {
  hero: {
    heading:
      "Explore a place with the comfort of an Airbnb and the predictability of a hotel.",
    linkTitle: "Hout Bay, South Africa",
    link: "https://goo.gl/maps/J2oNoYtcJZ4XULkU9",
    linkIcon: locationIcon,
    imgUrl: homeImg,
    isHome: true,
    showBookingOptons: true,
    ShowAvailability: true,
  },
  slider: {
    imgUrl: [slide1, slide2, slide3, slide4, slide5],
  },
}
